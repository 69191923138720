import { createUserPermalink as createPermalink, type PermalinkInfoDto } from 'cadenza/api-client/permalink-api';
import { ProcessingApi } from 'cadenza/api-client/processing-api';
import './permalink.css';
import { isFeatureAvailable } from 'cadenza/features';
import { logger } from 'cadenza/utils/logging';
import { errorAlert } from 'cadenza/alert';
import { MIMETYPE_XHTML } from 'cadenza/model/media-type';
import { cadenzaTopWindow } from 'cadenza/utils/iframe';
import { isAuditLogError } from 'cadenza/api-client/api';

import i18n from './permalink.properties';

type PermalinkResult = PermalinkInfoDto & { isDirectDownloadPossible: boolean };

const DEFAULT_MIMETYPE = MIMETYPE_XHTML;

/**
 * Loads the permalink info dialog dynamically and opens it.
 *
 * @param isDirectDownloadPossible - If True, direct download section will be created in the permalink info dialog.
 * @param baseUrl - Permalink url
 * @param aliases - Permalink parameter names
 * @return A promise resolved, when the dialog bundle is loaded and the dialog is shown.
 */
function showPermalinkInfo (isDirectDownloadPossible: boolean, baseUrl: string, aliases: string[]): Promise<void> {
  return import(/* webpackChunkName: "permalink-info-dialog" */ './permalink-info').then(({ PermalinkInfoDialog }) => {
    const dialog = new PermalinkInfoDialog(isDirectDownloadPossible, baseUrl, aliases);
    dialog.addEventListener('close', dialog.remove);
    cadenzaTopWindow.document.body.append(dialog);
    dialog.showModal();
  }).catch(err => logger.error('Failed to show extended permalink dialog', err));
}

export function createUserPermalink (mimeType: string, urlParams: URLSearchParams): Promise<void> {
  if (mimeType == null) {
    mimeType = DEFAULT_MIMETYPE;
  }
  return createPermalink(urlParams)
    .then(function (result) {
      return showPermalinkInfo(
        mimeType.indexOf('pdf') !== -1 && !isFeatureAvailable('ACCESS_MANAGER'),
        result.baseUrl,
        result.aliases);
    })
    .catch(error => {
      if (!isAuditLogError(error)) {
        errorAlert(i18n('error.userPermalink.title'), i18n('error.userPermalink.message'));
      }
    });
}

export function createExtendedPermalink (mimeType: string, urlParams: URLSearchParams) {
  if (mimeType == null) {
    mimeType = DEFAULT_MIMETYPE;
  }
  const processing = new ProcessingApi(urlParams);
  return Promise.all([ processing.getRepository(), processing.getConditions(), import(/* webpackChunkName: "permalink-extended-dialog" */ './extended-permalink-dialog') ])
    .then(([ repository, conditions, { ExtendedPermalinkDialog } ]) => {
      const dialog = new ExtendedPermalinkDialog(
        repository,
        conditions,
        mimeType,
        urlParams);

      dialog.addEventListener('close', () => {
        dialog.remove();
        if (dialog.returnValue) {
          // see comment in ExtendedPermalinkDialog
          const result = dialog.returnValue as unknown as PermalinkResult;
          showPermalinkInfo(result.isDirectDownloadPossible, result.baseUrl, result.aliases);
        }
      });

      cadenzaTopWindow.document.body.append(dialog);
      dialog.showModal();
    })
    .catch(err => logger.error('Failed to show extended permalink dialog', err));
}
