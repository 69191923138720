import type { EventKey, GenericEventListener } from 'cadenza/utils/event-util';
import { Observable } from 'cadenza/utils/event-util';
import type { Icon } from 'cadenza/utils/icon/icon';

export interface ActionOptions {
  /** The label of the action */
  label: string;

  /** An icon for the action */
  icon?: Icon;

  /* An optional tooltip */
  tooltip?: string;

  /** @deprecated Style classes are specific to a certain UI. */
  styleClass?: string[] | string;

  /**
   * ID to be used for auto-activated action (future feature)
   *
   * @deprecated Auto-activation is a map-specific feature.
   */
  id?: string;
}

/**
 * An action is a stateless abstraction over an executable functionality for reuse with different UIs.
 *
 * - Stateless means that actions must not have setters or observable state.
 * - The @deprecated parts of this API are not reusable with different UIs or they make the action stateful.
 * - Actions must import their `execute()` dependencies dynamically (`import()`), because they are
 *   executed only on demand and the dependencies are not needed for the initial page load.
 * - UI components must not depend on this API directly, but for example actions can be used with
 *   menus and toolbars, because the items of these components are structurally compatible with actions.
 */
export class Action extends Observable {

  readonly _label: string;
  _icon?: Icon;
  readonly #tooltip?: string;
  readonly _styleClass?: string[] | string;
  readonly _id?: string;
  _active: boolean;

  constructor ({ label, icon, id, styleClass, tooltip }: ActionOptions) {
    super();
    this._label = label;
    this._icon = icon;
    this._styleClass = styleClass;
    this._id = id;
    this._active = false;
    this.#tooltip = tooltip;

    // in case they're called without this
    this.execute = this.execute.bind(this);
    this.executeSync = this.executeSync.bind(this);
  }

  /**
   * Executes the action.
   *
   * Override this method if the action is executed synchronously, otherwise override {@link #execute}.
   */
  protected executeSync () {
    throw new Error('Not implemented');
  }

  /**
   * Executes the action.
   *
   * Override this method if the action is executed asynchronously, otherwise override {@link #executeSync}.
   *
   * @return A promise that is resolved when the action was executed
   */
  execute (): Promise<unknown> {
    try {
      this.executeSync();
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** @return The label of the action */
  get label () {
    return this._label;
  }

  /** @return An icon for the action */
  get icon () {
    return this._icon?.cloneNode(true) as Icon;
  }

  /** @deprecated Actions should be stateless. */
  set icon (icon) {
    this._icon = icon;
  }

  /** @deprecated Style classes are specific to a certain UI. */
  get styleClass (): string | string[] | undefined {
    return this._styleClass;
  }

  /**
   * ID to be used for auto-activated action (future feature)
   *
   * @deprecated Auto-activation is a map-specific feature.
   */
  get id () {
    return this._id;
  }

  /**
   * Indicates that buttons created from this action will be created as toggle buttons if property
   * returns true or false. True means the initial state is pressed, false means not pressed.
   * Undefined will result in a normal button to be created.
   *
   * @return If undefined a normal button, else a button with the returned initial pressed state is
   * to be created for this action.
   * @deprecated Being toggleable / pressable is specific to a certain UI.
   */
  get pressed (): boolean | undefined {
    return undefined;
  }

  /**
   * - If an action is not available, it must be hidden for the user.
   * - Availability is typically checked using {@link import('cadenza/features').isFeatureAvailable}.
   * - The `available` property doesn't allow arguments - Necessary context should be passed to the action's constructor.
   *
   * @return Whether the action is available for the user
   */
  get available () {
    return true;
  }

  /**
   * Checks whether the action is enabled.
   *
   * - If an action is disabled, it must be shown for the user, but disabled.
   * - The `enabledSync` property doesn't allow arguments - Necessary context should be passed to the action's constructor.
   * - Override this getter if the check is executed synchronously, otherwise override {@link #enabled}.
   *
   * @return Whether the action is currently enabled
   */
  protected get enabledSync () {
    return false;
  }

  /**
   * Checks whether the action is enabled.
   *
   * - If an action is disabled, it must be shown for the user, but disabled.
   * - The `enabled` property doesn't allow arguments - Necessary context should be passed to the action's constructor.
   * - Override this getter if the check is executed asynchronously, otherwise override {@link #enabledSync}.
   *
   * @return Whether the action is currently enabled
   */
  get enabled () {
    return Promise.resolve(this.enabledSync);
  }

  /**
   * Checks whether the action can be executed.
   *
   * Override this method if the check is executed synchronously, otherwise override {@link #isSupported}.
   *
   * @param context - The current context the action to check if supported for
   * @return Whether the action is supported
   * @deprecated Implement {#enabledSync instead}.
   */
  protected isSupportedSync (context?: unknown): boolean {
    return this.available && this.enabledSync;
  }

  /**
   * Checks whether the action can be executed.
   *
   * Override this method if the check is executed asynchronously, otherwise override {@link #isSupportedSync}.
   *
   * @param [context] - The current context the action to check if supported for
   * @return Whether the action is supported
   * @deprecated Implement {#enabled instead}.
   */
  isSupported (context: unknown = {}): Promise<boolean> {
    return Promise.resolve(this.isSupportedSync(context));
  }

  get tooltip (): string | undefined {
    return this.#tooltip;
  }

  /** @deprecated Being expandable is specific to a certain UI. */
  isExpandable (): boolean {
    return false;
  }

  /** @deprecated Use the {@link #enabled} property instead. */
  get disabled (): boolean {
    return false;
  }

  /** @deprecated An action should be stateless. */
  get active (): boolean {
    return this._active;
  }

  /** @deprecated An action should be stateless. */
  set active (active: boolean) {
    if (this._active !== active) {
      this._active = active;
      this.dispatchEvent('change:active');
    }
  }

  /** @deprecated An action should be stateless. */
  dispatchEvent (event: Event | string): boolean {
    return super.dispatchEvent(event);
  }

  /** @deprecated An action should be stateless. */
  addEventListener (type: string, callback: EventListenerOrEventListenerObject | null, options?: AddEventListenerOptions | boolean) {
    return super.addEventListener(type, callback, options);
  }

  /** @deprecated An action should be stateless. */
  on<E extends Event>(type: string, listener: GenericEventListener<E>, options?: AddEventListenerOptions): EventKey {
    return super.on(type, listener, options);
  }

  /** @deprecated An action should be stateless. */
  removeEventListener (type: string, callback: EventListenerOrEventListenerObject | null, options?: EventListenerOptions | boolean) {
    return super.removeEventListener(type, callback, options);
  }

  /** @deprecated An action should be stateless. */
  un (type: string, listener: EventListener, options?: AddEventListenerOptions) {
    super.un(type, listener, options);
  }

}
