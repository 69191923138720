import h from 'hyperscript';
import linkIcon from '@disy/cadenza-icons/link.svg';

import { setupPopupButton } from 'ui/popup/popup';
import { BUTTON_ICON_STYLE_CLASS, createButton } from 'ui/button/button';

import { icon } from 'cadenza/utils/icon/icon';
import { createProcessesButton } from 'cadenza/processes/processes-button-util';
import { getProcessings } from 'cadenza/api-client/processing-api';
import 'cadenza/header/info-button/info-button'; // used in toobarInfo.xhtml
import { on, unByKey } from 'cadenza/utils/event-util';
import { CONDITIONS_HIERARCHIES_CHANGE } from 'cadenza/conditions/event-types';
import { PermalinkView } from 'cadenza/permalink/permalink-view';

import i18n from './messages.properties';
import './subnav.css';

export class Subnav extends HTMLElement {

  #permalinkButton;
  #eventListenerKeys = [];

  constructor () {
    super();
    this._ready = new Promise(resolve => {
      this._resolveReady = resolve;
    });
  }

  connectedCallback () {
    this.classList.add('is-resizable');
    const theme = window.Disy.theme || {};
    this.classList.toggle('dark-bg', Boolean(theme['view-header-invert']));

    setTimeout(this._resolveReady); // when light DOM is ready

    // adjust processings to context.processing structure.
    const proc = () => getProcessings().then(
      processings => [ { processing: processings } ]);
    this._ready.then(() => {
      if (this.withProcesses) {
        this._processesButton = createProcessesButton(proc, { variant: 'borderless' });
        this.append(this._processesButton);
      }

      if (this._centerControls) {
        this._centerControls.forEach(control => this.append(control));
      }

      this.append(this._spacer = h('.d-spacer'));

      if (this.permalinkOptions) {
        this.#permalinkButton = createPermalinkButton(this.permalinkOptions);
        const filterController = document.querySelector('.d-filter-controller');
        if (filterController) {
          this.registerFilterControllerEvents(filterController);
        }
        this.append(this.#permalinkButton);
      }
    });
  }

  registerFilterControllerEvents (filterController) {
    unByKey(this.#eventListenerKeys);
    setTimeout(() => this._updatePermalinkButtonDisabled(filterController));
    this.#eventListenerKeys.push(
      on(filterController, [
        CONDITIONS_HIERARCHIES_CHANGE
      ], () => this._updatePermalinkButtonDisabled(filterController)));
  }

  _updatePermalinkButtonDisabled (filterController) {
    if (this.#permalinkButton) {
      this.#permalinkButton.disabled =
        !(this.permalinkOptions.isPermalinkCreationAllowed && filterController.validity.valid);
    }
  }

  disconnectedCallback () {
    unByKey(this.#eventListenerKeys);
  }

  get withProcesses () {
    return this.getAttribute('with-processes') === 'true';
  }

  get permalinkOptions () {
    const type = this.getAttribute('permalink-type');
    const mimetype = this.getAttribute('permalink-mimetype');
    const isPermalinkCreationAllowed = this.getAttribute('is-permalink-creation-allowed');
    return type && { type, mimetype, isPermalinkCreationAllowed };
  }

  addAction (action) {
    const button = createActionButton(action.icon, action.label, action.id, action.styleClass);
    button.hidden = true;
    this.addButton(button);
    return action.isSupported().then(supported => {
      button.onclick = () => action.execute();
      button.hidden = !supported;
      return button;
    });
  }

  addButton (button, { atFirstPosition = false } = {}) {
    this._ready.then(() => {
      if (atFirstPosition) {
        this.insertBefore(button, this.firstChild);
      } else {
        this.insertBefore(button, this._spacer.nextSibling);
      }
      this.lastToolbarWidth = this.getBoundingClientRect().width;
    });
  }

  removeElement (element) {
    if (!element) {
      return;
    }
    this.removeChild(element);
    this.lastToolbarWidth = this.getBoundingClientRect().width;
  }

  setCenterControls (controls) {
    if (this._centerControls) {
      this._centerControls.forEach(control => control.remove());
      this.lastToolbarWidth = this.getBoundingClientRect().width;
    }
    if (controls) {
      controls.forEach(control => this.insertBefore(control, this._spacer));
      this.lastToolbarWidth = this.getBoundingClientRect().width;
    }
    this._centerControls = controls;
  }

}

function createPermalinkButton (options) {
  const permalinkButton = createActionButton(icon(linkIcon), i18n('permalink.button.title'));
  permalinkButton.id = 'subnav-permalink-button';
  setupPopupButton(permalinkButton, () => new PermalinkView(options), { styleClass: 'd-permalink-menu' });
  permalinkButton.disabled = !options.isPermalinkCreationAllowed;
  return permalinkButton;
}

function createActionButton (buttonIcon, title, testid, styleClass) {
  const button = createButton([ buttonIcon, h('.visuallyhidden', title) ], {
    title,
    styleClass: [ styleClass, BUTTON_ICON_STYLE_CLASS ],
    variant: 'borderless'
  });
  if (testid) {
    button.dataset.testid = testid;
  }
  return button;
}

customElements.define('d-subnav', Subnav);
