import { ajax } from './api';
import { PARAMETER_CONDITION_HASH } from './api-constants';
import { MIMETYPE_JSON } from 'cadenza/model/media-type';

import type { RepositoryName } from 'cadenza/repository/model/repository-name';

/** @see net.disy.cadenza.permalink.web.dto.PermalinkDto */
interface PermalinkDto {
  name: string;
  selector: string;
  processings: string[];
  credentials: CredentialsDto;
  attributeAliases: AttributeAliasDto[];
  conditionValuesSetHash: string;
}

/** @see net.disy.cadenza.permalink.web.dto.CredentialsDto */
interface CredentialsDto {
  username: string;
  password: string;
}

/** @see net.disy.cadenza.permalink.web.dto.AttributeAliasDto */
interface AttributeAliasDto {
  condition: string;
  attribute: string;
  alias: string;
}

/** @see net.disy.cadenza.permalink.web.dto.PermalinkInfoDto */
export interface PermalinkInfoDto {
  baseUrl: string;
  aliases: string[];
}

export function createUserPermalink (urlParams: URLSearchParams): Promise<PermalinkInfoDto> {
  const selector = urlParams.get('selector');

  if (selector) {
    return ajax(`/userProcessingChainPermalinks?${urlParams.toString()}`, {
      traditional: true,
      method: 'POST'
    });
  }
  return ajax('/userMapPermalinks', {
    data: {
      mapId: urlParams.get('mapId')
    },
    method: 'POST'
  });
}

export function createExtendedPermalink (repositoryName: RepositoryName, data: PermalinkDto, urlParams: URLSearchParams): Promise<PermalinkInfoDto> {
  const requestData = { ...data };
  const conditionValuesSetHash = urlParams.get(PARAMETER_CONDITION_HASH);
  if (conditionValuesSetHash) {
    requestData.conditionValuesSetHash = conditionValuesSetHash;
  }
  return ajax<PermalinkInfoDto>(`/repositories/${repositoryName}/permalinks`, {
    method: 'POST',
    traditional: true,
    data: JSON.stringify(requestData),
    contentType: MIMETYPE_JSON
  });
}
