import linkIcon from '@disy/cadenza-icons/link.svg';

import { Action } from 'cadenza/action';
import { icon } from 'cadenza/utils/icon/icon';
import { createUserPermalink } from 'cadenza/permalink/permalink';

import i18n from './messages.properties';

export class UserPermalinkAction extends Action {

  readonly #mimetype;
  readonly #isPermalinkCreationAllowed;
  readonly #urlParams;

  constructor (mimetype: string, isPermalinkCreationAllowed: boolean, urlParams: URLSearchParams) {
    super({ label: i18n('map-actions:userPermalink'), icon: icon(linkIcon) });
    this.#mimetype = mimetype;
    this.#isPermalinkCreationAllowed = isPermalinkCreationAllowed;
    this.#urlParams = urlParams;
  }

  isSupportedSync () {
    return this.#isPermalinkCreationAllowed;
  }

  execute (): Promise<void> {
    return createUserPermalink(this.#mimetype, this.#urlParams);
  }

}
