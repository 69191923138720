import linkIcon from '@disy/cadenza-icons/link.svg';

import { Action } from 'cadenza/action';
import { icon } from 'cadenza/utils/icon/icon';
import { createExtendedPermalink } from 'cadenza/permalink/permalink';

import i18n from './messages.properties';

export class ExtendedPermalinkAction extends Action {

  readonly #type;
  readonly #mimetype;
  readonly #urlSearchParams;

  constructor (type: string, mimetype: string, urlSearchParams: URLSearchParams) {
    super({ label: i18n('map-actions:userPermalink'), icon: icon(linkIcon) });
    this.#type = type;
    this.#mimetype = mimetype;
    this.#urlSearchParams = urlSearchParams;
  }

  isSupportedSync () {
    return (this.#type !== 'Map');
  }

  execute () {
    return createExtendedPermalink(this.#mimetype, this.#urlSearchParams);
  }

}
