import { isFeatureAvailable } from 'cadenza/features';
import h from 'hyperscript';
import { UserPermalinkAction } from 'cadenza/actions/user-permalink-action';
import { ExtendedPermalinkAction } from 'cadenza/actions/extended-permalink-action';
import { logger } from 'cadenza/utils/logging';

import i18n from './permalink.properties';
import './permalink-view.css';

const name = 'd-permalink-view';

export class PermalinkView extends HTMLElement {

  #type;
  #mimetype;
  #isPermalinkCreationAllowed;
  #urlSearchParams;

  constructor ({
    type,
    mimetype,
    isPermalinkCreationAllowed,
    urlSearchParams = new URLSearchParams(location.search)
  } = {}) {
    super();
    this.#type = type;
    this.#mimetype = mimetype;
    this.#isPermalinkCreationAllowed = isPermalinkCreationAllowed;
    this.#urlSearchParams = urlSearchParams;
  }

  connectedCallback () {
    this.classList.add(name);
    const permalinkAction = new UserPermalinkAction(this.#mimetype, this.#isPermalinkCreationAllowed, this.#urlSearchParams);
    this.append(
      h('h1.d-dropdown-heading', i18n('view.heading')),
      h('p', i18n('view.text')),
      h('button.button.button-primary',
        { onclick: () => permalinkAction.execute() },
        i18n('view.user.label')));

    if (isFeatureAvailable('EXTENDED_PERMALINK_CREATION')) {
      const extendedPermalinkAction = new ExtendedPermalinkAction(this.#type, this.#mimetype, this.#urlSearchParams);
      extendedPermalinkAction.isSupported().then(supported => {
        if (supported) {
          this.append(h('button.button.button-primary',
            { onclick: () => extendedPermalinkAction.execute() },
            i18n('view.extended.label')));
        }
      }).catch(error => logger.error(error));
    }
  }

}

customElements.define(name, PermalinkView);
